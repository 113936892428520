import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { UserData, getUserData } from './getUserData';
import { sendErrorLog } from '../functions/sendErrorLog';

export type UserDataState = {
  data: UserData | null;
  isInitialized: boolean;
};

const MIN_LOADING_TIME_MS = 340;

export const UserDataContext = createContext<UserDataState>({
  data: null,
  isInitialized: false,
});

export const UserDataProvider = ({ children }: React.PropsWithChildren) => {
  const auth = getAuth();
  const [data, setData] = useState<UserData | null>(null);
  const [isInitialized, setInitialized] = useState(false);

  const authStateChanged = async (user: User | null) => {
    if (!user) {
      setData(null);
      setInitialized(true);
      return;
    }

    const waitPromise = new Promise((resolve) => setTimeout(resolve, MIN_LOADING_TIME_MS));
    Promise.all([getUserData(user), waitPromise])
      .then(([userData]) => {
        setInitialized(true);
        setData(userData);
      })
      .catch((error) => {
        setInitialized(true);
        sendErrorLog(error);
        console.error('Error while fetching user data:', {
          error: error.message,
          code: error.code,
          userId: user?.uid,
          timestamp: new Date().toISOString(),
        });
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);

    return () => unsubscribe();
  }, [auth]);

  return (
    <UserDataContext.Provider value={{ data, isInitialized }}>{children}</UserDataContext.Provider>
  );
};
