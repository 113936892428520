import { useEffect, useMemo } from 'react';
import { useUserData } from './services/firebase/context/useUserData';
import { setActiveDataRole } from './features/Search/state/actions/searchParams';

export const SetDefaultDataRole = () => {
  const { data } = useUserData();

  const defaultDataRole = useMemo(() => {
    return data ? [...data.storedUser.dataRoles].sort((a, b) => a.weight - b.weight)[0] : null;
  }, [data]);

  useEffect(() => {
    if (defaultDataRole) {
      setActiveDataRole(defaultDataRole.roleName);
    }
  }, [defaultDataRole]);

  return null;
};
