/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import posthog from 'posthog-js';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import { sendConfirmationEmail } from '@/services/firebase/functions/sendConfirmationEmail';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { logout } from '@/services/firebase/functions/logout';
import devlog from '@/utils/devlog';

export const EmailConfirmationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const disabledTimeoutRef = useRef<number | null>(null);
  const [sendAgainDisabled, setSendAgainDisabled] = useState(false);

  const [searchParams] = useSearchParams();
  const authUser = getAuth().currentUser;
  const emailInParams = searchParams.get('email');
  const activeEmail = authUser?.email || emailInParams;
  const [isEmailVerified, setIsEmailVerified] = useState(authUser?.emailVerified);

  // Send an email once component mounts
  useEffect(() => {
    sendEmail();
  }, [activeEmail]);

  // Redirect to login page if email is verified
  useEffect(() => {
    if (isEmailVerified) {
      navigate('/auth');
    }
  }, [isEmailVerified]);

  // Set the email in the URL to the email of the current user if not the same
  useEffect(() => {
    if (emailInParams !== activeEmail) {
      navigate(`/auth/confirm-email?email=${activeEmail}`);
    }
  }, [activeEmail, emailInParams]);

  // Reload the id token
  const reloadIdToken = async () => {
    const authUser = getAuth().currentUser;
    if (authUser) {
      await authUser.reload();
      devlog('Reloaded id token', authUser.emailVerified);
      setIsEmailVerified(authUser.emailVerified);
    }
  };

  // Trigger reloadIdToken every second
  useEffect(() => {
    const interval = setInterval(() => {
      devlog('Reloading id token');
      reloadIdToken().catch(() => {});
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Clear the timeout when component unmounts
  useEffect(() => {
    return () => {
      if (disabledTimeoutRef.current) {
        window.clearTimeout(disabledTimeoutRef.current);
      }
    };
  }, []);

  // Resend the confirmation email
  const sendEmail = () => {
    if (activeEmail === null) return;
    setIsLoading(true);
    setSendAgainDisabled(true);
    disabledTimeoutRef.current = window.setTimeout(() => {
      setSendAgainDisabled(false);
    }, 5000);
    sendConfirmationEmail(activeEmail)
      .then(() => {
        setIsLoading(false);
        posthog.capture(POSTHOG_EVENT.RESEND_EMAIL_VERIFICATION, { emailInParams });
      })
      .catch((err) => {
        sendErrorLog(err);
        setIsLoading(false);
      });
  };

  // Navigate to login page
  const loginClick = () => {
    logout()
      .then(() => {
        navigate('/auth');
      })
      .catch(() => {});
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription text={t('confirmEmailPage.confirmEmailMessage')} />
          <p className="self-center bg-black rounded-smaller px-4 py-1 text-white mx-3 mb-4 text-center tracking-wide">
            {emailInParams}
          </p>
          <button
            onClick={sendEmail}
            disabled={sendAgainDisabled}
            className={`${isLoading ? 'animate-pulse cursor-default' : sendAgainDisabled ? '' : 'hover:bg-qura-neutral-silver'} border ${sendAgainDisabled ? 'border-qura-neutral-silver/50' : 'border-qura-neutral-silver'} disabled:opacity-50 text-xs font-medium bg-qura-neutral-ghost px-2 py-1 self-center rounded-smaller`}>
            {isLoading ? t('confirmEmailPage.isLoading') : t('confirmEmailPage.resendLink')}
          </button>
          <div className="h-6" />
          <p className="text-tiny text-center">{t('confirmEmailPage.spamEmail')}</p>

          <div className="h-20" />
          <AuthSubmitButton onClick={loginClick}>
            {t('confirmEmailPage.backToLogin')}
          </AuthSubmitButton>
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
    </AuthBackground>
  );
};
