import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ExamplePrompts } from './ExamplePrompts';
import Feedback from '../../components/Feedback/Feedback';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import UserDropDown from '../../components/UserDropDown/UserDropDown';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { searchIsActive } from '../../state/hooks/useIsSearchActive';
import { DataRoleToggle } from '../../components/DataRoleToggle/DataRoleToggle';
import { HelpButton } from '../../components/HelpButton/helpButton';
import { Icons } from '@/assets';
import SearchHistoryModal from '@/features/Search/components/SearchHistoryModal/SearchHistoryModal.tsx';

export const SearchPromptPage = () => {
  const [searchId, setSearchId] = useState<string | null>(null);
  const navigate = useNavigate();

  const [isSearchHistoryModalOpened, setSearchHistoryModalOpened] = useState(false);

  useEffect(() => {
    createNewSearch()
      .then(({ searchId }) => {
        setSearchId(searchId);
      })
      .catch(() => {});
  }, []);

  const handleSearch = (searchId: string) => {
    sendSearchParams(searchId);
    navigate(`/search/${searchId}`);
  };

  const onSearch = () => {
    if (searchId && searchIsActive(searchId)) {
      handleSearch(searchId);
      return;
    }

    createNewSearch()
      .then(({ searchId }) => {
        handleSearch(searchId);
      })
      .catch(() => {});
  };

  const selectDocument = async (documentId: string) => {
    navigate(`/document/${documentId}`);
  };

  const handleSearchHistoryModalStatus = () => {
    setSearchHistoryModalOpened(!isSearchHistoryModalOpened);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen w-screen">
        <Icons.LogoText className="h-5" />
        <div className="h-2" />
        <DataRoleToggle />
        <div className="h-5" />
        <div className="flex flex-col w-3/5 max-w-[1200px]">
          <SearchBar
            noTextAnimation
            onSearch={onSearch}
            disabled={false}
            selectDocument={selectDocument}
            onOpenHistoryModal={handleSearchHistoryModalStatus}
          />
          <div className="px-4">
            <ExamplePrompts onSearch={onSearch} />
          </div>
        </div>
      </div>
      <div className="fixed right-10 top-10 z-30">
        <UserDropDown />
      </div>
      {isSearchHistoryModalOpened && (
        <SearchHistoryModal onClose={handleSearchHistoryModalStatus} onSearch={onSearch} />
      )}
      <Feedback />
      <ToastContainer className="pointer-events-auto" />
      <HelpButton />
    </>
  );
};
