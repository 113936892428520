import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { databaseInfoResponseSchema } from '@/services/api/apiValidation.ts';
import { mapAxiosError } from '@/utils/mapAxiosError';

export async function getDatabaseInfo() {
  const token = await getAuthToken();
  const response = await axios
    .get(`${RESOURCE_API_URL}/database/dbinfo`, standardAxiosOptions(token))
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });
  const validated = databaseInfoResponseSchema.parse(response.data);
  // eslint-disable-next-line
  //@ts-ignore
  return validated;
}

export const useDatabaseInfo = () => {
  return useQuery({
    queryKey: ['database_info'],
    queryFn: () => catchAndLogError(() => getDatabaseInfo()),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
